import {
  Attachment,
  CreateOrUpdateMalfunctionInput,
  DropdownItem,
  GetMalfunctionGroupsDropdownInput,
  GetMalfunctionTypesDropdownInput,
  MalfunctionItem,
  Nullable,
  SearchBaseInput,
  ValidationErrors,
} from "../../../types/index";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import axios, { AxiosError } from "axios";
import { saveAs } from "file-saver";

interface MalfunctionState {
  processingData: boolean;
  malfunctionGroups?: Nullable<DropdownItem[]>;
  malfunctionTypes?: Nullable<DropdownItem[]>;
  loadingMalfunctionGroups: boolean;
  loadingMalfunctionTypes: boolean;
  data: MalfunctionItem[];
  fetchingData: boolean;
  total: number;
  params: Nullable<SearchBaseInput>;
  selectedMalfunction: Nullable<CreateOrUpdateMalfunctionInput>;
}

export const getMalfunctions = createAsyncThunk(
  "malfunctions/getMalfunctions",
  async (params: SearchBaseInput) => {
    const response = await axios.get("/v1/Malfunction/Search", { params });
    return {
      items: response.data.data.items,
      totalCount: response.data.data.totalCount,
    };
  }
);

export const getMalfunction = createAsyncThunk(
  "malfunctions/getMalfunction",
  async (id: number, { rejectWithValue }) => {
    if (id === 0) return;
    try {
      const result = await axios.get(`/v1/Malfunction/${id}`);

      return {
        item: result.data.data,
      };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const createMalfunction = createAsyncThunk(
  "malfunctions/createMalfunction",
  async (input: FormData, { rejectWithValue }) => {
    try {
      await axios.post(`/v1/Malfunction`, input, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateMalfunction = createAsyncThunk(
  "malfunctions/updateMalfunction",
  async (input: CreateOrUpdateMalfunctionInput, { rejectWithValue }) => {
    try {
      await axios.put(`/v1/Malfunction`, input);
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteMalfunction = createAsyncThunk(
  "malfunctions/deleteMalfunction",
  async (input: number, { rejectWithValue }) => {
    try {
      await axios.delete(`/v1/Malfunction/${input}`);
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMalfunctionGroups = createAsyncThunk(
  "malfunctions/getMalfunctionGroups",
  async (deviceId: number, { rejectWithValue }) => {
    try {
      const searchParams: GetMalfunctionGroupsDropdownInput = {
        skip: 0,
        take: 100000,
        deviceId,
      };
      const result = await axios.get(
        "/v1/MalfunctionGroup/malfunction-groups",
        {
          params: searchParams,
        }
      );

      return {
        data: result.data.data.items,
      };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMalfunctionTypes = createAsyncThunk(
  "malfunctions/getMalfunctionTypes",
  async (malfunctionGroupId: number, { rejectWithValue }) => {
    if (malfunctionGroupId === 0) return null;
    try {
      const searchParams: GetMalfunctionTypesDropdownInput = {
        skip: 0,
        take: 100000,
        malfunctionGroupId,
      };
      const result = await axios.get("/v1/MalfunctionType/malfunction-types", {
        params: searchParams,
      });

      return {
        data: result.data.data.items,
      };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAttachment = createAsyncThunk(
  "malfunctions/deleteAttachment",
  async (id: number, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.delete(`/v1/MalfunctionAttachment/${id}`);
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadAttachment = createAsyncThunk(
  "malfunctions/uploadAttachment",
  async (attachment: FormData, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post("/v1/MalfunctionAttachment", attachment, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadFile = createAsyncThunk(
  "malfunctions/downloadFile",
  async (file: Attachment, { dispatch, getState, rejectWithValue }) => {
    const result = await axios.get(
      `/v1/MalfunctionAttachment/Download/${file.id}`,
      {
        responseType: "blob",
        timeout: 30000,
      }
    );

    saveAs(new Blob([result.data]), file.fileName);
  }
);

const initialState: MalfunctionState = {
  processingData: false,
  loadingMalfunctionGroups: false,
  loadingMalfunctionTypes: false,
  data: [],
  fetchingData: false,
  total: 1,
  params: null,
  selectedMalfunction: null,
};

export const malfunctionsSlice = createSlice({
  name: "malfunctions",
  initialState,
  reducers: {
    handleDeleteMalfunction: (state, action) => {
      if (state.data)
        state.data = state.data.filter((element) => {
          return element.id !== action.payload;
        });
    },
    handleAttachmentDelete: (state, action) => {
      state.selectedMalfunction!.attachments =
        state.selectedMalfunction!.attachments!.filter((element) => {
          return element.id !== action.payload;
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMalfunctionGroups.fulfilled, (state, action) => {
        const dropdownItems: Nullable<DropdownItem[]> = [];

        action.payload.data.forEach((item: any) => {
          dropdownItems!.push({
            value: item.id,
            label: item.name,
          });
        });

        state.malfunctionGroups = dropdownItems;

        state.loadingMalfunctionGroups = false;
      })
      .addCase(getMalfunctionGroups.pending, (state, action) => {
        state.loadingMalfunctionGroups = true;
      })
      .addCase(getMalfunctionTypes.fulfilled, (state, action) => {
        if (!action.payload) {
          state.malfunctionTypes = null;
        } else {
          const dropdownItems: Nullable<DropdownItem[]> = [];

          action.payload.data.forEach((item: any) => {
            dropdownItems!.push({
              value: item.id,
              label: item.name,
            });
          });

          state.malfunctionTypes = dropdownItems;

          state.loadingMalfunctionTypes = false;
        }
      })
      .addCase(
        getMalfunction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.selectedMalfunction = action.payload?.item;
          state.fetchingData = false;
        }
      )
      .addCase(getMalfunction.pending, (state) => {
        state.fetchingData = false;
      })
      .addCase(getMalfunction.rejected, (state) => {
        state.fetchingData = false;
      })
      .addCase(getMalfunctionTypes.pending, (state, action) => {
        state.loadingMalfunctionTypes = true;
      })
      .addCase(
        createMalfunction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.processingData = false;
        }
      )
      .addCase(createMalfunction.pending, (state) => {
        state.processingData = true;
      })
      .addCase(createMalfunction.rejected, (state) => {
        state.processingData = false;
      })
      .addCase(getMalfunctions.fulfilled, (state, action) => {
        state.total = action.payload.totalCount;
        state.data = action.payload.items;
        state.params = action.meta.arg;
        state.fetchingData = false;
      })
      .addCase(getMalfunctions.pending, (state) => {
        state.fetchingData = true;
      })
      .addCase(getMalfunctions.rejected, (state) => {
        state.fetchingData = false;
      });
  },
});

export const { handleDeleteMalfunction, handleAttachmentDelete } =
  malfunctionsSlice.actions;

export default malfunctionsSlice.reducer;
