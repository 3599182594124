import storage from "redux-persist/lib/storage";
// ** Reducers Imports
import layout from "./layout";
import { apiGenerator } from "./api/apiGenerator";
import navbar from "./navbar";
import clientLocation from "../views/client/store/index";
import devices from "../views/devices/store/index";
import masterdata from "./masterdata";
import malfunctions from "@src/views/malfunctions/store";
import shipping from "@src/views/shipping/store";
import { combineReducers } from "redux";
import authStore from "./slices/authStore";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  blacklist: ["api"],
};

const rootReducer = combineReducers({
  [apiGenerator.reducerPath]: apiGenerator.reducer,
  auth: authStore,
  navbar,
  layout,
  clientLocation,
  devices,
  masterdata,
  malfunctions,
  shipping,
});

export { rootPersistConfig, rootReducer };
