import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CreateShipmentInput,
  DownloadShippingLabelInput,
  DropdownItem,
  Nullable,
  SearchBaseInput,
  ShipmentListItem,
  ShippingDetailsOutput,
  ValidationErrors,
} from "@src/types";
import axios, { AxiosError } from "axios";
import { saveAs } from "file-saver";

interface ShippingState {
  addressList?: Nullable<DropdownItem[]>;
  loadingAddressList: boolean;
  data: ShipmentListItem[];
  fetchingData: boolean;
  total: number;
  params: Nullable<SearchBaseInput>;
  showDetailsModal: boolean;
  shippingDetails: Nullable<ShippingDetailsOutput>;
  loadingShippingDetails: boolean;
}

export const createShipment = createAsyncThunk(
  "shipping/createShipment",
  async (input: CreateShipmentInput, { rejectWithValue }) => {
    try {
      const result = await axios.post(`/v1/Shipment`, input);

      return result.data;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteShipment = createAsyncThunk(
  "shipping/deleteShipment",
  async (input: number, { rejectWithValue }) => {
    try {
      await axios.delete(`/v1/Shipment/${input}`);
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const getShipments = createAsyncThunk(
  "shipping/getShipments",
  async (params: SearchBaseInput) => {
    const response = await axios.get("/v1/Shipment/Search", { params });
    return {
      items: response.data.data.items,
      totalCount: response.data.data.totalCount,
    };
  }
);

export const getAddresses = createAsyncThunk(
  "shipping/getAddresses",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.get("/v1/ShippingAddress/GetDropdown");

      return {
        data: result.data.data,
      };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadShipmentAttachment = createAsyncThunk(
  "shipping/uploadShipmentAttachment",
  async (attachment: FormData, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post("/v1/Shipment/upload-attachment", attachment, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const getShippingDetails = createAsyncThunk(
  "shipping/getShippingDetails",
  async (id: number, { rejectWithValue }) => {
    if (id === 0) return;
    try {
      const result = await axios.get(`/v1/Shipment/${id}`);

      return {
        item: result.data.data,
      };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadImage = createAsyncThunk(
  "shipping/downloadFile",
  async (
    input: DownloadShippingLabelInput,
    { dispatch, getState, rejectWithValue }
  ) => {
    const result = await axios.get(`/v1/Shipment/Download/${input.id}`, {
      responseType: "blob",
      timeout: 30000,
    });

    saveAs(new Blob([result.data]), input.fileName);
  }
);

export const downloadAttachment = createAsyncThunk(
  "shipping/downloadAttachment",
  async (
    input: DownloadShippingLabelInput,
    { dispatch, getState, rejectWithValue }
  ) => {
    const result = await axios.get(
      `/v1/Shipment/Download-attachment/${input.id}`,
      {
        responseType: "blob",
        timeout: 30000,
      }
    );

    saveAs(new Blob([result.data]), input.fileName);
  }
);

export const deleteImage = createAsyncThunk(
  "shipping/deleteImage",
  async (id: number, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.delete(`/v1/shipment/delete-attachment/${id}`);
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState: ShippingState = {
  data: [],
  loadingAddressList: false,
  fetchingData: false,
  total: 1,
  params: null,
  showDetailsModal: false,
  shippingDetails: null,
  loadingShippingDetails: false,
};

export const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    handleDeleteShipment: (state, action) => {
      if (state.data)
        state.data = state.data.filter((element) => {
          return element.id !== action.payload;
        });
    },
    handleCloseModal: (state) => {
      state.showDetailsModal = false;
    },
    handleShowModal: (state) => {
      state.showDetailsModal = true;
    },
    handleAttachmentDelete: (state, action) => {
      state.shippingDetails!.attachments =
        state.shippingDetails!.attachments!.filter((element) => {
          return element.id !== action.payload;
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShipments.fulfilled, (state, action) => {
        state.total = action.payload.totalCount;
        state.data = action.payload.items;
        state.params = action.meta.arg;
        state.fetchingData = false;
      })
      .addCase(getShipments.pending, (state) => {
        state.fetchingData = true;
      })
      .addCase(getShipments.rejected, (state) => {
        state.fetchingData = false;
      })
      .addCase(getAddresses.fulfilled, (state, action) => {
        const dropdownItems: Nullable<DropdownItem[]> = [];

        action.payload.data?.forEach((item: any) => {
          dropdownItems!.push({
            value: item.id,
            label: item.name,
          });
        });

        state.addressList = dropdownItems;

        state.loadingAddressList = false;
      })
      .addCase(getAddresses.pending, (state) => {
        state.loadingAddressList = true;
      })
      .addCase(getShippingDetails.fulfilled, (state, action) => {
        state.shippingDetails = action.payload?.item;
        state.loadingShippingDetails = false;
      })
      .addCase(getShippingDetails.pending, (state) => {
        state.loadingShippingDetails = true;
      })
      .addCase(getShippingDetails.rejected, (state) => {
        state.loadingShippingDetails = false;
      });
  },
});

export const {
  handleDeleteShipment,
  handleCloseModal,
  handleShowModal,
  handleAttachmentDelete,
} = shippingSlice.actions;

export default shippingSlice.reducer;
