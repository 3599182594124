import { LocationSearchInput, SearchBaseInput } from "./../types/index";
import {
  Nullable,
  ValidationErrors,
  DropdownItem,
  DropdownBaseInput,
} from "@types";
// ** Redux Imports
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import axios, { AxiosError } from "axios";

//https://redux.js.org/usage/usage-with-typescript
interface MasterdataState {
  manufacturersDropdown?: Nullable<DropdownItem[]>;
  manufacturersLoaded: boolean;
  deviceTypesDropdown?: Nullable<DropdownItem[]>;
  deviceTypesLoaded: boolean;
  deviceTemplatesDropdown?: Nullable<DropdownItem[]>;
  deviceTemplatesLoaded: boolean;
  customers?: Nullable<DropdownItem[]>;
  customerLocations?: Nullable<DropdownItem[]>;
}

export const getManufacturers = createAsyncThunk(
  "masterdata/getManufacturers",
  async (input, { rejectWithValue }) => {
    try {
      const searchParams: DropdownBaseInput = { skip: 0, take: 100000 };
      const result = await axios.get("/v1/Manufacturer/Manufacturers", {
        params: searchParams,
      });

      return {
        data: result.data.data.items,
      };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDeviceTypes = createAsyncThunk(
  "masterdata/getDeviceTypes",
  async (input, { rejectWithValue }) => {
    try {
      const searchParams: DropdownBaseInput = { skip: 0, take: 100000 };
      const result = await axios.get("/v1/DeviceType/device-types", {
        params: searchParams,
      });

      return {
        data: result.data.data.items,
      };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDeviceTemplates = createAsyncThunk(
  "masterdata/getDeviceTemplates",
  async (input, { rejectWithValue }) => {
    try {
      const searchParams: DropdownBaseInput = { skip: 0, take: 100000 };
      const result = await axios.get("/v1/DeviceTemplate/device-templates", {
        params: searchParams,
      });

      return {
        data: result.data.data.items,
      };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomers = createAsyncThunk(
  "masterdata/getCustomers",
  async (params: SearchBaseInput) => {
    const response = await axios.get("/v1/Client/Search", { params });
    return {
      items: response.data.data.items,
      totalCount: response.data.data.totalCount,
    };
  }
);

export const getCustomerLocations = createAsyncThunk(
  "masterdata/getCustomerLoctions",
  async (params: LocationSearchInput) => {
    const response = await axios.get("/v1/Location/Search", { params });
    return {
      items: response.data.data.items,
      totalCount: response.data.data.totalCount,
    };
  }
);

const initialState: MasterdataState = {
  manufacturersLoaded: false,
  deviceTypesLoaded: false,
  deviceTemplatesLoaded: false,
};

export const masterdataSlice = createSlice({
  name: "masterdata",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getManufacturers.fulfilled, (state, action) => {
      const dropdownItems: Nullable<DropdownItem[]> = [];

      action.payload.data.forEach((item: any) => {
        dropdownItems!.push({
          value: item.id,
          label: item.name,
        });
      });

      state.manufacturersDropdown = dropdownItems;

      state.manufacturersLoaded = true;
    });
    builder.addCase(getDeviceTypes.fulfilled, (state, action) => {
      const dropdownItems: Nullable<DropdownItem[]> = [];

      action.payload.data.forEach((item: any) => {
        dropdownItems!.push({
          value: item.id,
          label: item.name,
        });
      });

      state.deviceTypesDropdown = dropdownItems;

      state.deviceTypesLoaded = true;
    });
    builder
      .addCase(getDeviceTemplates.fulfilled, (state, action) => {
        const dropdownItems: Nullable<DropdownItem[]> = [];

        action.payload.data.forEach((item: any) => {
          dropdownItems!.push({
            value: item.id,
            label: item.name,
          });
        });

        state.deviceTemplatesDropdown = dropdownItems;

        state.deviceTemplatesLoaded = true;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        const tenantDropdownItems = [] as DropdownItem[];

        action.payload.items.forEach((element: any) => {
          tenantDropdownItems.push({
            value: element.id,
            label: element.name,
          });
        });

        state.customers = tenantDropdownItems;
      })
      .addCase(getCustomerLocations.fulfilled, (state, action) => {
        const locationsDropdown = [] as DropdownItem[];

        action.payload.items.forEach((element: any) => {
          locationsDropdown.push({
            value: element.id,
            label: element.name,
          });
        });

        state.customerLocations = locationsDropdown;
      });
  },
});

export const {} = masterdataSlice.actions;

export default masterdataSlice.reducer;
