import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "@src/types";
import {
  GetClientListItemOutput,
  GetClientOutput,
  GetLocationListItemOutput,
  GetLocationOutput,
} from "./../../../types/index";

interface ClientLocationState {
  openCustomerAccordion: string;
  // customersTotal: number;
}

const initialState: ClientLocationState = {
  openCustomerAccordion: "",
};

export const clientLocationSlice = createSlice({
  name: "clientLocation",
  initialState,
  reducers: {
    // handleShowCustomerBody: (state, action) => {
    //   const updatedItems = state.customers!.map((item) => {
    //     if (item!.id === parseInt(action.payload)) {
    //       return { ...item!, showBody: true };
    //     }

    //     return { ...item, showBody: false };
    //   });

    //   state.customers = updatedItems;
    // },
    // clearLocations: (state) => {
    //   state.locations = [];
    // },
    handleAccordionToggle: (state, action) => {
      state.openCustomerAccordion = action.payload;
    },
  },
});

export const { handleAccordionToggle } = clientLocationSlice.actions;

export default clientLocationSlice.reducer;
