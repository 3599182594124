// ** Logo
// import logo from "@src/assets/images/logo/appLogoTextBlack.png";
import startupAnimation from "@src/assets/animations/application-load.json";
import { useEffect } from "react";
import lottie from "lottie-web/build/player/lottie_light";

const SpinnerComponent = () => {
  useEffect(() => {
    lottie.destroy("startup-animation");
    lottie.loadAnimation({
      container: document.querySelector("#startup-animation"),
      animationData: startupAnimation,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
      name: "startup-animation",
    });
  }, []);
  return (
    <div className="fallback-spinner app-loader">
      <img
        className="fallback-logo"
        src="https://via.placeholder.com/250x100"
        alt="logo"
        width={250}
      />
      <div id="startup-animation" style={{ width: 400, height: 400 }} />
    </div>
  );
};

export default SpinnerComponent;
