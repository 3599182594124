import { createSlice } from "@reduxjs/toolkit";

interface DevicesState {
  openDeviceAccordion: string;
}
const initialState: DevicesState = {
  openDeviceAccordion: "",
};

export const devicesSlice = createSlice({
  name: "installations",
  initialState,
  reducers: {
    handleAccordionToggle: (state, action) => {
      state.openDeviceAccordion = action.payload;
    },
  },
});

export const { handleAccordionToggle } = devicesSlice.actions;

export default devicesSlice.reducer;
